<template>
    <div class="breadcrumb grid-container hidden md:block relative z-10">
        <nav class="breadcrumb__content md:py-2" role="navigation" aria-label="{{ 'aria.labels.breadcrumb'|t('dico') }}">
            <ul class="flex items-center">
                <li
                    v-for="(item, index) in items"
                    :key="`breadcrumb-item-${index}`"
                    :aria-current="index === items.length -1 ? 'page': ''"
                    :class="{'text-grey-40': baseColor === 'grey', 'text-white': baseColor === 'white'}"
                    class="text-indication-form flex items-center"
                >
                    <a v-if="index === 0" :href="item.url">
                        {{ item.label }}
                    </a>
                    <template v-else-if="index > 0 && index < items.length - 1">
                        <img :src="require(`@/static/images/icons/${baseColor === 'grey' ? 'chevron-small': 'chevron-small-white'}.svg`)" class="w-2 h-2 mx-2" aria-hidden="true"/>
                        <a :href="item.url">
                            {{ item.label }}
                        </a>
                    </template>
                    <template v-else>
                        <img :src="require(`@/static/images/icons/${baseColor === 'grey' ? 'chevron-small': 'chevron-small-white'}.svg`)" class="w-2 h-2 mx-2" aria-hidden="true"/>
                        <span>{{ item.label }}</span>
                    </template>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',

        props: {
            items: { type: Array, default: () => [] }
        },

        data: () => ({
            baseColor: 'grey'
        }),

        mounted() {
            // Check if first block of the page has indication to use the transparent header
            this.baseColor = document.querySelectorAll('.page > *')[0]?.classList.contains('header-transparent') ? 'white' : 'grey';
        }
    }
</script>
