<template>
    <div class="publication-search-bar">
        <div ref="bar" :class="{'publication-search-bar__bar--opened': mobileOpened}" class="block publication-search-bar__bar border border-grey-80">
            <div v-show="device === 'mobile' && !mobileOpened" class="publication-search-bar__mobile-content w-full h-full flex flex-row justify-between items-center pl-6 pr-4 md:hidden cursor-pointer" @click="mobileOpened = true">
                <div class="publication-search-bar__mobile-secondary-text">
                    <p class="body-2 whitespace-nowrap truncate">{{ currentValues.mainSelect ? currentValues.mainSelect : translations.mainSelectPlaceholder}}</p>
                    <div class="flex items-center text-ellipsis">
                        <p
                            :class="{'text-black': currentValues.secondarySelect1, 'text-brand-grey': !currentValues.secondarySelect1 }"
                            class="text-indication-form whitespace-nowrap truncate"
                        >
                            {{ currentValues.secondarySelect1 ? currentValues.secondarySelect1 : translations.secondarySelect1Placeholder}}
                        </p>
                        <p
                            :class="{'text-black': currentValues.secondarySelect2, 'text-brand-grey': !currentValues.secondarySelect2 }"
                            class="text-indication-form whitespace-nowrap truncate"
                        >
                            {{ currentValues.secondarySelect2 ? currentValues.secondarySelect2 : translations.secondarySelect2Placeholder}}
                        </p>
                    </div>
                </div>
                <button class="bg-brand-blue w-12 h-12 flex justify-center items-center rounded-full" @click="mobileOpened = true">
                    <img src="~@/static/images/icons/search-white.svg" />
                </button>
            </div>
            <div v-show="device === 'desktop' || device === 'mobile' && mobileOpened" class="publication-search-bar__form-container relative md:h-full p-6 md:p-0">
                <div class="flex flex-row justify-between md:hidden">
                    <h3 class="">{{ translations.mobileHeading }}</h3>
                    <button class="flex w-8 h-8 justify-center items-center rounded-full border border-dashed border-grey-63 ml-4 flex-shrink-0" @click="mobileOpened = false">
                        <img src="~@/static/images/icons/cross-black.svg" />
                    </button>
                </div>
                <form ref="form" class="publication-search-bar__form h-full flex flex-col md:flex-row items-start md:items-center mt-8 md:mt-0 md:pr-2" method="get">
                    <div v-if="$slots['main-select']" class="publication-search-bar__field flex flex-col md:flex-row flex-grow">
                        <slot name="main-select"/>
                    </div>
                    <div v-if="$slots['secondary-select-1']" class="publication-search-bar__field flex flex-col md:flex-row flex-grow mt-4 md:mt-0">
                        <slot name="secondary-select-1"/>
                    </div>
                    <div v-if="$slots['secondary-select-2']" class="publication-search-bar__field flex flex-col md:flex-row flex-grow mt-4 md:mt-0">
                        <slot name="secondary-select-2"/>
                    </div>

                    <button type="submit" class="btn btn--plain-blue btn--M mt-4 md:mt-0">{{ translations?.submitLabel }}</button>
                </form>
                <div  class="absolute top-1/2 right-0 transform translate-x-full -translate-y-1/2">
                </div>
            </div>
        </div>
        <button v-if="!isInputsEmpty" class="btn-text btn-text--blue ml-4 md:ml-8 mt-3" @click="resetInputs">Réinitialiser</button>
    </div>
</template>

<script>
    export default {
        name: 'PublicationSearchBar',

        props: {
            translations: { type: Object, default: () => {} },
            currentValues: { type: Object, default: () => {} }
        },

        data: () => ({
            mobileOpened: false,
            device: null,
            breakpoint: 768,
            inputs: [],
            isInputsEmpty: true
        }),

        mounted() {
            // On window resize
            window.addEventListener('resize', this.resizeHandler);

            // Execute resize handler once
            this.resizeHandler();

            this.$nextTick(() => {
                // Get all inputs
                this.inputs = document.querySelectorAll('.publication-search-bar__form input, .publication-search-bar__form select');

                // Go for each inputs
                this.inputs.forEach((input) => {
                    // If an input change
                    input.addEventListener('change', this.inputChange);
                });

                this.inputChange();

                const dateInput = this.$el.querySelector('input[type=date]');

                if (dateInput) {
                    const todayDate = new Date;
                    dateInput.setAttribute('min', `${todayDate.getFullYear()}-${ ("0" + (todayDate.getMonth() + 1)).slice(-2) }-${("0" + todayDate.getDate()).slice(-2)}`);
                }
            });
        },

        methods: {
            resizeHandler() {
                // Reset values
                this.mobileOpened = false;

                // Detect what type of device it is
                this.device = (window.innerWidth < this.breakpoint) ? 'mobile' : 'desktop';
            },

            inputChange() {
                this.isInputsEmpty = this.checkInputsState();
            },

            checkInputsState() {
                let inputsEmpty = true;
                if (this.inputs && this.inputs.length > 0) {
                    this.inputs.forEach((input) => {
                        console.log('value', input.value);
                        if (input.value !== '') {
                            inputsEmpty = false;
                        }
                    });
                }

                return inputsEmpty;
            },

            resetInputs() {
                if (this.inputs && this.inputs.length > 0) {
                    this.inputs.forEach((input) => {
                        input.value = '';
                    });
                }

                this.inputChange();

                this.$refs.form.submit();
            }
        }
    }
</script>

<style lang="scss">
    .publication-search-bar {
        &__bar {
            @apply rounded-full;
            height: 4.875rem;

            &--opened {
                @apply h-auto border-none rounded-lg ring-8 ring-offset-0 ring-brand-blue-grey;
            }

            @screen md {
                @apply h-16 rounded-full;
            }
        }

        &__field {
            @apply relative w-full h-full flex;

            @screen md {
                &:not(:first-child) {
                    &:before {
                        @apply relative block w-px h-10 mt-3 bg-grey-94 z-0;
                        content: '';
                    }
                }
            }
        }

        &__select-label {
            @apply absolute top-2 left-8;
            z-index: 2;
        }

        &__select {
            @apply w-full h-16 appearance-none bg-transparent pt-5 rounded-full transition-colors duration-300 px-8 border border-grey-80 truncate;
            z-index: 1;

            @screen md {
                @apply h-full border-none;
            }

            &[value=""] {
                @apply text-grey-63;
            }

            &:hover {
                @apply bg-grey-98;
            }

            &:focus {
                @apply outline-0 ring-4 ring-blue-36 ring-offset-0;
            }
        }

        &__mobile-secondary-text {
            max-width: calc(100% - 72px);

            p:not(:first-child) {
                &:before {
                    @apply inline-flex w-0.5 h-0.5 bg-brand-grey rounded-full mx-2 align-middle;
                    content: '';
                }
            }
        }
    }
</style>
