<template>
    <div class="image-popin">
        <button class="image-popin__opener w-full" @click="popinState = true">
            <slot></slot>
        </button>

        <transition-group name="fade">
            <template v-if="popinState">
                <div class="image-popin__dimmer bg-black/40 fixed inset-0 w-full h-full z-30 cursor-pointer" @click="popinState = false"></div>
                <div class="image-popin__popin bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 md:p-4 z-30 flex flex-col items-center">
                    <img v-if="image.url" :src="image.url" />
                    <div v-if="image.title || image.imageCredit" class="w-full flex justify-between mb-1 mt-3 md:mt-4 md:mb-0">
                        <p v-if="image.title" class="caption text-brand-grey">{{ image.title }}</p>
                        <p v-if="image.imageCredit" class="caption text-brand-grey">{{ image.imageCredit }}</p>
                    </div>
                    <button class="image-popin__cross absolute -top-10 right-0 w-8 h-8 rounded-full bg-white flex justify-center items-center" @click="popinState = false">
                        <img src="~@/static/images/icons/cross-black.svg" aria-hidden="true" class="w-4 h-4" />
                    </button>
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script>
    export default {
        name: 'ImagePopin',

        props: {
            image: { type: Object, default: () => {} }
        },

        data: () => ({
            popinState: false
        }),

        watch: {
            popinState() {
                document.querySelector('body').style.overflow = (this.popinState) ? 'hidden' : 'visible';
            }
        }
    }
</script>

<style lang="scss">
    .image-popin {
        &__popin {
            width: calc(100% - 16px);

            @screen md {
                @apply w-auto;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
