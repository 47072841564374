<template>
    <div class="collapse-panel">
        <div ref="opener" :class="{'collapse-panel__opener--opened': opened}" class="collapse-panel__opener" @click="togglePanel" @keydown.enter.right.left="togglePanel" tabindex="0">
            <slot name="opener"></slot>
        </div>
        <div ref="container" class="collapse-panel__content-container h-0 overflow-hidden transition-all duration-300">
            <div ref="inner" class="collapse-panel__content-inner pt-4 pb-2" :inert="!opened ? 'inert' : false">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CollapsePanel',

        data: () => ({
            opened : false
        }),

        mounted() {

        },

        methods: {
            togglePanel() {
                this.$refs.container.style.height = (this.opened) ? '0px' : `${this.$refs.inner.clientHeight}px`;

                this.opened = !this.opened;
            }
        }
    }
</script>

<style lang="scss">
    .collapse-panel {
        &__opener {
            @apply cursor-pointer;

            &--opened {
                .collapse-panel__plus {
                    @apply hidden;
                }

                .collapse-panel__minus {
                    @apply block;
                }
            }
        }

        &__link {
            @apply flex items-center justify-between;

            &:after {
                @apply block w-4 h-4;
                content: '';
                background-image: url('~@/static/images/icons/chevron-right-white.svg');
            }
        }
    }
</style>
