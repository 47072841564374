<template>
    <div class="donation-simulator flex flex-col items-center bg-white text-black p-6 md:py-8 md:px-10 rounded-lg">
        <ol class="flex border border-grey-63 rounded-full overflow-hidden">
            <li v-for="(amount, index) in amounts" :key="`donation-amount-${index}`">
                <button
                    :class="{'border-l border-grey-63': index > 0 && currentIndex !== index, 'bg-brand-blue border-l border-brand-blue text-white': currentIndex === index }"
                    class="body-2 px-4 py-1.5"
                    @click="currentIndex = index"
                >
                    {{ amount }} €
                </button>
            </li>
        </ol>

        <p class="h4 mt-4 md:mt-6">{{ reductedSentence }}</p>

        <slot name="cta"></slot>
    </div>
</template>

<script>
    export default {
        name: 'DonationSimulator',

        props: {
            amounts: { type: Array, default: () => [] },
            donationResultSentence: { type: String, default: '' }
        },

        data: () => ({
            currentIndex: 0
        }),

        computed: {
            reductedAmount() {
                const amount = this.amounts[this.currentIndex];
                return Math.round(amount - (amount * 66)/100);
            },

            reductedSentence() {
                return this.donationResultSentence.replace('{amount}', this.reductedAmount);
            }
        }
    }
</script>
