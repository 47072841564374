<template>
    <div class="read-more-text">
        <p :class="textStyle" class="read-more-text__inner inline">
            <span :class="{'line-clamp-3 md:line-clamp-none': !deployText, 'line-clamp-none': deployText }">{{ text }}</span>
            <span :class="{'hidden': deployText}" class="inline md:hidden cursor-pointer" @click="deployText = true">Lire la suite</span>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ReadMoreText',

        props: {
            text: { type: String, default: '' },
            textStyle: { type: String, default: '' }
        },

        data: () => ({
            deployText: false
        })
    }
</script>

