<template>
    <div v-if="slots" class="timeline-content flex flex-col md:flex-row mt-10 md:mt-0">
        <div class="md:w-1/3 relative flex justify-center items-center overflow-hidden h-12 md:h-full">
            <div class="border-b md:border-l border-dashed border-black w-full h-px md:h-full md:w-px"></div>
            <div ref="dotsAndTriangle" class="absolute top-0 left-0 w-full h-full transition-all duration-700">
                <div class="flex flex-row md:flex-col absolute left-6 md:left-1/2 top-1/2 md:top-24 transform -translate-y-1/2  md:translate-y-0 md:-translate-x-1/2">
                    <div
                        v-for="i in slots.length"
                        :key="`dot-${i}`"
                        :class="{ 'ml-20 md:ml-0 md:mt-25': i > 1}"
                        class="w-1.5 h-1.5 bg-black rounded-full"
                    ></div>
                </div>
                <img
                    ref="triangle"
                    src="~@/static/images/icons/geodesic.svg"
                    class="timeline-content__triangle absolute left-3 top-1/2 md:left-1/2 md:top-20 transform -translate-y-1/2  md:translate-y-0 md:-translate-x-1/2 -rotate-90 md:rotate-0 transition-all duration-500"
                />
            </div>
        </div>
        <div class="md:w-2/3 md:pb-5 mt-6 md:mt-0">
            <transition-group name="fade" mode="out-in">
                <div v-for="i in slots.length" :key="`timeline-content-${i}`">
                    <div v-show="i === currentIndex" class="w-full">
                        <slot :name="`content-${i}`"></slot>
                    </div>
                </div>
            </transition-group>
            <div class="flex items-center mt-4 md:mt-6">
                <button class="btn btn--S btn--dotted-black" :disabled="currentIndex === 1" @click="updateIndex(-1)">Précédent</button>
                <button class="btn btn--S btn--dotted-black ml-4" :disabled="currentIndex === slots.length" @click="updateIndex(1)">Suivant</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TimelineContent',

        data: () => ({
            slots: null,
            currentIndex: 1,
            breakpoint: 768
        }),

        mounted() {
            this.slots = Object.values(this.$slots);
        },

        methods: {
            updateIndex(direction) {
                // Update current index
                this.currentIndex = this.currentIndex + direction;

                if (window.innerWidth >= this.breakpoint) {
                    // If we go forward
                    if (direction === 1) {
                        // Rotate triangle
                        this.$refs.triangle.style.transform = `translateX(-50%) rotate(0deg)`;
                        // Wait a bit
                        setTimeout(() => {
                            // Move triangle
                            this.$refs.triangle.style.marginTop = `${(this.currentIndex - 1) * 108}px`;
                            // Move everything
                            this.$refs.dotsAndTriangle.style.marginTop = `-${(this.currentIndex - 1) * 108}px`;
                        }, 500)
                    } else {
                        // Rotate triangle
                        this.$refs.triangle.style.transform = `translateX(-50%) rotate(-180deg)`;
                        // Move triangle
                        this.$refs.dotsAndTriangle.style.marginTop = `-${(this.currentIndex - 1) * 108}px`;
                        setTimeout(() => {
                            // Move everything
                            this.$refs.triangle.style.marginTop = `${(this.currentIndex - 1) * 108 - 10}px`;
                        }, 500)
                    }
                } else {
                    // If we go forward
                    if (direction === 1) {
                        // Rotate triangle
                        this.$refs.triangle.style.transform = `translateY(-50%) rotate(-90deg)`;
                        // Wait a bit
                        setTimeout(() => {
                            // Move triangle
                            this.$refs.triangle.style.marginLeft = `${(this.currentIndex - 1) * 85}px`;
                            // Move everything
                            this.$refs.dotsAndTriangle.style.marginLeft = `-${(this.currentIndex - 1) * 85}px`;
                        }, 500)
                    } else {
                        // Rotate triangle
                        this.$refs.triangle.style.transform = `translateY(-50%) rotate(90deg)`;
                        // Move triangle
                        this.$refs.dotsAndTriangle.style.marginLeft = `-${(this.currentIndex - 1) * 85}px`;
                        setTimeout(() => {
                            // Move everything
                            this.$refs.triangle.style.marginLeft = `${(this.currentIndex - 1) * 85 - 12}px`;
                        }, 500)
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    .timeline-content {
        &__triangle {
            width: 51px;
            height: 44px;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
