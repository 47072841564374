import "./assets/styles/main.scss";

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import FlashMessage from "@/app/components/FlashMessage";
import HeaderCustom from "@/app/components/HeaderCustom";
import PublicationSearchBar from "@/app/components/PublicationSearchBar";
import ReadMoreText from "@/app/components/ReadMoreText";
import CollapsePanel from "@/app/components/CollapsePanel";
import DonationSimulator from "@/app/components/DonationSimulator";
import VideoCustom from "@/app/components/VideoCustom";
import TimelineContent from "@/app/components/TimelineContent";
import ImagePopin from "@/app/components/ImagePopin";
import Breadcrumb from "@/app/components/Breadcrumb";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        FlashMessage,
        HeaderCustom,
        PublicationSearchBar,
        ReadMoreText,
        CollapsePanel,
        DonationSimulator,
        VideoCustom,
        TimelineContent,
        ImagePopin,
        Breadcrumb
    }
}).mount('#app');
