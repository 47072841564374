<template>
    <header ref="header" :class="`header--${baseColor}`" class="header z-20" role="banner">
        <transition name="fade">
            <div v-show="submenuOpened" class="header__dimmer fixed inset-0 w-full h-full bg-black/40 cursor-pointer" @click="submenuOpened = false"></div>
        </transition>
        <div ref="panel" class="header__panel bg-white transition-transform duration-500"></div>
        <div class="grid-container h-full relative">
            <div class="grid-row h-full">
                <div class="grid-col w-full h-full flex justify-between items-center lg:justify-start">
                    <div class="header__logo relative h-full flex-shrink-0">
                        <slot name="logo"></slot>
                    </div>
                    <div
                        :class="{'hidden': !mobileMenuOpened && device === 'mobile', 'header__menus--mobile': mobileMenuOpened && device === 'mobile'}"
                        class="header__menus lg:w-full lg:flex lg:justify-between lg:items-center lg:ml-6 xl:ml-10"
                    >
                        <nav
                            id="mainmenu"
                            class="header__main-nav"
                            role="navigation"
                            :aria-label="translations.arialLabelNavMain"
                        >
                            <ol class="flex flex-col justify-between lg:justify-start lg:flex-row lg:items-center w-full lg:w-auto">
                                <li ref="menuItems" v-for="(item, index) in menu" :key="`main-menu-item-${index}`">
                                    <a
                                        v-if="item.type === 'simpleCta'"
                                        :href="item.cta.linkedUrl"
                                        :target="item.cta.target"
                                        :class="{
                                            'link-underline link-underline--white text-white': baseColor === 'transparent' && !submenuOpened && device === 'desktop' || device === 'mobile' && !mobileMenuOpened,
                                            'link-underline link-underline--black text-black': baseColor === 'white' && device === 'desktop' || submenuOpened && device === 'desktop' || device === 'mobile' && mobileMenuOpened,
                                            'mt-6 lg:mt-0 lg:ml-4 xl:ml-6': index > 0,
                                            'flex': device === 'mobile' && currentSubmenuIndex === null,
                                            'hidden': device === 'mobile' && currentSubmenuIndex !== null
                                        }"
                                        class="block text-nav transition-colors duration-500"
                                    >
                                        {{ item.cta.customText ?? item.cta.linkedTitle }}
                                    </a>
                                    <div v-if="item.type === 'submenu'" :class="{'mt-6 lg:mt-0 lg:ml-4 xl:ml-6': index > 0}" class="lg:relative">
                                        <button
                                            :class="{
                                                'link-underline link-underline--white text-white': baseColor === 'transparent' && !submenuOpened && device === 'desktop',
                                                'link-underline link-underline--black text-black': baseColor === 'white' && device === 'desktop' || submenuOpened && device === 'desktop',
                                                'header__submenu-opener--opened': currentSubmenuIndex === index,
                                                'flex': device === 'mobile' && currentSubmenuIndex === null,
                                                'hidden': device === 'mobile' && currentSubmenuIndex !== null
                                            }"
                                            class="header__nav-item header__submenu-opener text-nav transition-all duration-500"
                                            @click="itemWithSubmenuClickHandler(index)"
                                        >
                                            {{ item.heading }}
                                        </button>
                                        <transition name="fade">
                                            <ol
                                                v-show="currentSubmenuIndex === index && submenuOpened && device === 'desktop' || currentSubmenuIndex === index && mobileMenuOpened && device === 'mobile'"
                                                ref="submenus"
                                                class="header__submenu absolute top-36 md:top-0 left-0 md:left-full lg:top-20 lg:left-0"
                                            >
                                                <li class="mb-8 lg:mb-0 lg:hidden">
                                                    <button class="header__mobile-back-link h4" @click="currentSubmenuIndex = null">{{ item.heading }}</button>
                                                </li>
                                                <li
                                                    v-for="(cta, index) in item.submenu"
                                                    :key="`submenu-item-${index}`"
                                                    :class="{'mt-6 lg:mt-4': index > 0}"
                                                    class="text-nav whitespace-nowrap"
                                                >
                                                    <a :href="cta.linkedUrl" :target="cta.target" :aria-label="cta['aria-label']">
                                                        {{ cta.customText ?? cta.linkedTitle }}
                                                    </a>
                                                </li>
                                            </ol>
                                        </transition>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <nav class="header__secondary-nav" role="navigation" :aria-label="translations.arialLabelNavSecondary">
                            <ol class="flex flex-col lg:flex-row items-center">
                                <li class="hidden lg:flex items-center">
                                    <a v-if="searchLink" :href="searchLink.url" :aria-label="searchLink['aria-label']" class="transition-transform duration-300 hover:scale-110">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
                                                  :stroke="(baseColor === 'white' || submenuOpened) ? 'black' : 'white'"
                                                  stroke-width="2"
                                                  stroke-linecap="square"
                                                  stroke-linejoin="round"
                                                  class="transition-colors duration-300"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li v-if="mainCta && mainCta.url" class="w-full lg:w-auto lg:ml-4">
                                    <a
                                        :href="mainCta.url"
                                        :target="mainCta.target"
                                        :aria-label="mainCta['aria-label']"
                                        :class="{'btn--dotted-black': baseColor === 'white' && device === 'desktop' || submenuOpened  && device === 'desktop' || device === 'mobile' && mobileMenuOpened, 'btn--dotted-white': baseColor === 'transparent' && !submenuOpened && device === 'desktop'}"
                                        class="block btn btn--S w-full lg:w-auto"
                                    >{{ mainCta.label }}</a>
                                </li>
                                <li v-if="secondaryCta && secondaryCta.url" class="w-full lg:w-auto mt-4 lg:mt-0 lg:ml-4">
                                    <a
                                        :href="secondaryCta.url"
                                        :target="secondaryCta.target"
                                        :aria-label="secondaryCta['aria-label']"
                                        class="block btn btn--S btn--plain-blue w-full lg:w-auto"
                                    >{{ secondaryCta.label }}</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <nav class="flex items-center lg:hidden">
                        <ol class="flex items-center" :class="mobileMenuOpened ? 'z-10' : ''">
                            <li role="search">
                                <a v-if="searchLink" :href="searchLink.url" :aria-label="searchLink['aria-label']">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
                                              :stroke="(baseColor === 'white' || mobileMenuOpened) ? 'black' : 'white'"
                                              stroke-width="2"
                                              stroke-linecap="square"
                                              stroke-linejoin="round"
                                              class="transition-colors duration-300"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li class="ml-6">
                                <button
                                    :class="{'text-black': baseColor === 'white' || mobileMenuOpened, 'text-white': baseColor === 'transparent' && !mobileMenuOpened, 'header__burger-menu--opened': mobileMenuOpened}"
                                    class="header__burger-menu"
                                    @click="mobileMenuOpened = !mobileMenuOpened"
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'HeaderCustom',

        props: {
            menu: { type: Array, default: () => [] },
            searchLink: { type: Object, default: () => {} },
            mainCta: { type: Object, default: () => {} },
            secondaryCta: { type: Object, default: () => {} },
            translations: { type: Object, default: () => {} },
        },

        data: () => ({
            baseColor: 'white',

            breakpoint: 1024,
            device: 'desktop',

            mobileMenuOpened: false,
            submenuOpened: false,
            currentSubmenuIndex: null
        }),

        watch: {
            submenuOpened() {
                // Get the submenu in question
                const submenu = (this.submenuOpened) ? this.$refs.menuItems[this.currentSubmenuIndex].querySelector('.header__submenu') : null;

                this.$nextTick(() => {
                    if (submenu) {
                        // Update the height of the panel
                        this.$refs.panel.style.height = `${168 + submenu.clientHeight}px`;
                    }

                    // Show it
                    this.$refs.panel.style.transform = (this.submenuOpened) ? "translate(0,0)" :  "translate(0,-100%)"
                });

                if (!this.submenuOpened) {
                    this.currentSubmenuIndex = null;
                }

                // Block scroll
                document.querySelector('html, body').style.overflow = (this.submenuOpened) ? 'hidden' : 'visible';
            },

            mobileMenuOpened() {
                // Show panel
                this.$refs.panel.style.transform = (this.mobileMenuOpened) ? "translate(0,0)" :  "translate(100%, 0)";

                // Block scroll
                document.querySelector('html, body').style.overflow = (this.mobileMenuOpened) ? 'hidden' : 'visible';
            },

            currentSubmenuIndex() {
                console.log('sub index', this.currentSubmenuIndex);
            }
        },

        mounted() {
            // Check if first block of the page has indication to use the transparent header
            this.baseColor = document.querySelectorAll('.page > *')[0]?.classList.contains('header-transparent') ? 'transparent' : 'white';

            // On resize
            window.addEventListener('resize', this.resizeHandler);

            this.$nextTick(() => {
                // Execute resize handler once
                this.resizeHandler();
            });
        },

        methods: {
            // When a menu item with submenu is clicked
            itemWithSubmenuClickHandler(index) {
                // If on desktop
                if (this.device === 'desktop') {
                    // Open the submenu if the current submenu index is different from current index
                    this.submenuOpened = !(this.currentSubmenuIndex === index);

                    // Set the index of the current submenu
                    this.currentSubmenuIndex = (this.submenuOpened) ? index : null;

                    console.log(this.submenuOpened, this.currentSubmenuIndex);
                }
                // Mobile
                else {
                    // Set the index of the current submenu
                    this.currentSubmenuIndex = index;
                }
            },

            mobileMenuBack() {
                this.currentSubmenuIndex = null;
            },

            // On resize
            resizeHandler() {
                // Reset all variables
                this.mobileMenuOpened = false;
                this.submenuOpened = false;

                // Detect what type of device it is
                this.device = (window.innerWidth < this.breakpoint) ? 'mobile' : 'desktop';

                // Place the panel to a correct place for device
                this.$refs.panel.style.transform = (this.device === 'mobile') ? "translate(100%,0)" :  "translate(0, -100%)";

                const logoHeight = this.$refs.header.querySelector('.header__logo-link').clientHeight;
                const headerHeight = this.$refs.header.clientHeight;

                this.$refs.header.style.marginBottom = `${logoHeight - headerHeight}px`;
            }
        }
    }
</script>

<style lang="scss">
    .header {
        @apply h-16;

        @screen lg {
            height: 4.5rem;
        }

        &--transparent {
            @apply absolute top-0 w-full z-10;
        }

        &__panel {
            @apply fixed w-full h-full top-0 left-0 transform translate-x-full;

            @screen lg{
                @apply w-full absolute top-0 left-0 right-0 transform -translate-y-full;
            }
        }

        &__logo {
            width: 4.5625rem;

            @screen lg {
                width: 6.5rem;
            }
        }

        &__logo-link {
            @apply absolute top-0 left-0;
        }

        &__burger-menu {
            @apply w-6 h-6 flex flex-col justify-center items-center;

            > span {
                @apply block w-5 h-0.5 bg-current transition-all duration-300;
                transform-origin: left;

                &:not(:first-child) {
                    @apply mt-1;
                }
            }

            &--opened {
                span:first-child {
                    transform: rotate(46deg);
                }

                > span:nth-child(2) {
                    opacity: 0;
                }

                span:last-child {
                    margin-top: 6px;
                    transform: rotate(-46deg);
                }
            }
        }

        &__menus {
            &--mobile {
                @apply flex flex-col justify-between fixed h-full w-full top-0 left-0 pt-36 pb-6;
            }
        }

        &__main-nav, &__secondary-nav {
            @apply px-4;

            @screen lg {
                @apply p-0;
            }
        }

        &__submenu {
            @apply px-4;

            @screen lg {
                @apply px-0;
            }
        }

        &__nav-item {
            @apply w-full justify-between items-center;

            &:after {
                @apply block w-4 h-4;
                content: '';
                background-image: url('~@/static/images/icons/chevron-small-black.svg');
            }

            @screen lg {
                &:after {
                    @apply hidden;
                }
            }
        }

        &__submenu-opener {
            @screen lg {
                @apply relative;

                &:after {
                    @apply block h-px w-0 bg-black absolute -bottom-6 -left-2 transition-all duration-300;
                    content: '';
                }

                &--opened {
                    &:after {
                        width: calc(100% + 16px);
                    }
                }
            }
        }

        &__mobile-back-link {
            @apply flex items-center;

            &:before {
                @apply block w-6 h-6 mr-2;
                content: '';
                background-image: url('~@/static/images/icons/chevron-left.svg');
            }
        }
    }

    .link-underline {
        @apply relative;

        &:after {
            content: '';
            @apply block w-0 h-px absolute -bottom-2 left-0 transition-all duration-300;
        }

        &--white:after {
            @apply bg-white;
        }

        &--black:after {
            @apply bg-black;
        }

        &:hover:after {
            @apply w-full;
        }
    }

    .fade-enter-active {
        transition: opacity 0.5s ease;
        transition-delay: 0.3s;
    }

    .fade-leave-active {
        transition: opacity 0.4s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
