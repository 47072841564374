<template>
    <div class="video-custom relative w-full h-full">
        <div ref="videos" class="video-custom__videos w-full h-0 overflow-hidden">
            <div v-if="$slots['video']" ref="video" class="video-custom__video w-full h-full">
                <slot name="video"></slot>
            </div>
            <div v-if="$slots['video-mobile']" ref="videoMobile" class="video-custom__video-mobile md:hidden w-full h-full">
                <slot name="video-mobile"></slot>
            </div>
            <div v-if="$slots['video-desktop']" ref="videoDesktop" class="video-custom__video-desktop hidden md:block w-full h-full">
                <slot name="video-desktop"></slot>
            </div>
        </div>
        <div v-if="$slots['poster']" ref="poster"  class="video-custom__poster w-full h-full">
            <slot name="poster"></slot>
        </div>
        <div ref="playButton" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <button class="btn-play" @click="playVideo"></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VideoCustom',

        props: {
            solo: { type: Boolean, default: false }
        },

        data: () => ({
            breakpoint: 768,
            video: null
        }),

        methods: {
            playVideo() {
                // Get iframe
                const video = (this.solo) ? this.$refs.video.querySelector('iframe') : (window.innerWidth < this.breakpoint) ? this.$refs.videoMobile.querySelector('iframe') : this.$refs.videoDesktop.querySelector('iframe');
                // Prepare source
                const src = video.getAttribute('src') +((video.getAttribute('src').includes('?')) ? '&autoplay=1' : '?autoplay=1');
                // Set the source
                video.setAttribute('src', src);

                // Hide what must be hidden and show video
                this.$refs.poster.style.display = 'none';
                this.$refs.playButton.style.display = 'none';
                this.$refs.videos.style.height = '100%';
            }
        }
    }
</script>

<style lang="scss">
    .video-custom {
        iframe {
            @apply w-full h-full;
        }

        &__poster {
            figure {
                @apply w-full h-full;
            }

            img {
                @apply w-full h-full object-cover;
            }
        }
    }
</style>
